import React from "react";
import PageWrapper from "../components/PageWrapper";
import SEO from '../components/SEO';

const Contact = () => { 
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-hide",
          headerFluid: false,
          footerStyle: "none",
        }}>
        <SEO
          title="Free Trial | Contact us for more information"
          description="Try for free"
        />
        {/* <Hero className="position-relative pt-15 pt-md-31 pt-lg-25 pb-14 pb-md-19 pb-lg-33 position-relative overflow-hidden" />  
        <Wave color="#F7F9FC" />          */}
        <div style={{height:"100vh"}} className="grey d-flex align-items-center">
          <div className="container">
            {/* Contact Form */}
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <h3 className="font-size-9 text-digma-dark mt-lg-0 mt-md-30">Start a free 30-day trial</h3>
                <p>Get the full Digma Interactive Video Experience and see it in action, no strings attached!</p>
                <div className="videoWrapper d-none d-md-block">
                  <iframe  width="100%" height="300" src="https://www.youtube.com/embed/9cdSa66T_BA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>              
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="pt-0 pb-10 max-w-536 mx-auto">
                  <form
                    name="free-trial-email"
                    method="POST"
                    action="thank-you"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    data-aos="fade-up"
                    data-aos-duration={1100}
                    data-aos-once="true"
                  >
                    <input type="hidden" name="form-name" value="free-trial-email" />
                    {/* Company Name */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="text"
                        name="name"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="First Name"
                        id="company"
                        required
                      />
                    </div>
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="text"
                        name="surname"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Last Name"
                        id="company"
                        required
                      />
                    </div>                    
                    {/* Email */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="email"
                        name="email"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Work Email"
                        id="email"
                        required
                      />
                    </div>
                    {/* Company Name */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="number"
                        name="number"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Contact Number"
                        id="number"
                        required
                      />
                    </div>
                    {/* Company Name */}
                    <div className="form-group mb-7 position-relative">
                    <input
                        type="text"
                        name="position"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Company Position"
                        id="position"
                        required
                      />
                    </div>
                    <div className="button">
                      <button
                        type="submit"
                        href="/#"
                        className="btn btn-digma h-55 w-100 rounded-4"
                      >
                        Submit
                      </button>
                    </div>
                  </form>

                </div>

              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
